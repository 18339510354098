<template>
  <div class="EducationSystem">
    <img src="../../assets/product/EducationSystem/1.png" class="headerimg" />

    <div class="margintopb">
      <div class="title">营销拓客，增加生源，提高收入</div>
      <div class="margintop70">
        <div class="cuthead width75 flexb">
          <div class="cuttitle" v-for="(item, index) in lista" :key="index" @click="indexa = index">
            <div class="cuttext">{{ item.title }}</div>
            <div class="line" :style="{background: (indexa == index?'#1663ff':'')}"></div>
          </div>
        </div>
        <div class="cutimg margintop70 width80">
          <img :src=lista[indexa].url />
        </div>
      </div>
    </div>

    <div class="margintopb">
      <div class="title">知识付费，助学互动，线上教育一站式解决方案</div>
      <div class="margintop70">
        <div class="cuthead width75 flexb cutheadb">
          <div class="cuttitle" v-for="(item, index) in listb" :key="index" @click="indexb = index">
            <div class="cuttext">{{ item.title }}</div>
            <div class="line" :style="{background: (indexb == index?'#1663ff':'')}"></div>
          </div>
        </div>
        <div class="cutimg margintop70 width80">
          <img :src=listb[indexb].url />
        </div>
      </div>
    </div>

    <div class="width80">
        <img src="../../assets/product/EducationSystem/7.png" style="width: 100%" />
    </div>

    <div class="width80 padbottom">
      <div class="margintopb">
        <img src="../../assets/product/EducationSystem/8.png" style="width: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      indexa:0,
      lista: [
        {
          title: "多端，全渠道覆盖获客",
          url: require("../../assets/product/EducationSystem/2.png"),
        },
        {
          title: "分销，裂变增加学生",
          url: require("../../assets/product/EducationSystem/3.png"),
        },
        {
          title: "线上转化，线下教学",
          url: require("../../assets/product/EducationSystem/4.png"),
        },
      ],
      indexb:0,
      listb: [
        {
          title: "多种付费形式",
          url: require("../../assets/product/EducationSystem/5.png"),
        },
        {
          title: "丰富助学工具",
          url: require("../../assets/product/EducationSystem/6.png"),
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
/deep/.cutheadb{
  padding: 0px 157px;
  width: 714px;
}

.cuthead {
  border-bottom: 1px solid #d8d8d8;
  padding: 0px 109px;
  box-sizing: border-box;
  .cuttitle {
    font-size: 22px;
    font-weight: 500;
    color: #1663ff;
    line-height: 30px;
    cursor: pointer;
  
  }

    .cuttext{
      margin-bottom: 14px;
    }

  .line{
    margin: auto;
    height: 2px;
    width: 58px;

  }
}
</style>